import "./App.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage/LandingPage";
import BetaSignup from "./Pages/BetaSignup/BetaSignup";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/beta-signup" element={<BetaSignup />} />
    </Routes>
  );
}

export default App;
