import { Link } from "react-router-dom";

import logo from "../../Assets/Logo/covlantLogoLightTheme.svg";

const Header = () => {
  const landingPageActionButtonStyle = `px-3 py-6  h-[46px] rounded-[12px] border-solid border-[#2742AB] border font-bold text-base items-center font-satoshi`;

  return (
    <div className="w-full sm:max-w-[1170px] mx-auto">
      <div className="flex flex-col sm:flex-row justify-between px-2 sm:px-4 py-2">
        <div className="flex flex-row justify-between">
          <Link to="/" className="py-6">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={`sm:flex block`}>
          <div className="flex flex-col sm:flex-row items-center gap-4">
            {/* <button
              className={`${landingPageActionButtonStyle} text-[#2742AB]`}
              onClick={() => window.open("https://app.covlant.ai/login")}
            >
              Sign In
            </button> */}
            <button
              className={`${landingPageActionButtonStyle} bg-[#2742AB] text-white`}
              onClick={() => (window.location.href = "/beta-signup")}
            >
              Beta Signup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
