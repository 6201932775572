import React from "react";
import { Link } from "react-router-dom";

import facebookIcon from "../../Assets/Icons/Light/facebookIcon.svg";
import twitterIcon from "../../Assets/Icons/Light/twitterIcon.svg";
import linkedInIcon from "../../Assets/Icons/Light/linkedInIcon.svg";
import instagramIcon from "../../Assets/Icons/Light/instagramIcon.svg";

const Footer = () => {
  return (
    <footer
      className={`w-full max-w-[1170px] h-[236px] sm:h-[126px] mx-auto flex flex-col gap-y-8 py-5`}
    >
      <div className="border-solid border-t border-[#141B34]/[0.1]"></div>
      <div className="flex flex-col sm:flex-row items-center gap-y-8 justify-between px-3">
        <div className="flex flex-row justify-between w-[112px] leading-5	">
          <div>
            <Link to="/covlant/favebook">
              <img src={facebookIcon} alt="Facebook" />
            </Link>
          </div>
          <div>
            <Link to="/covlant/twitter">
              <img src={twitterIcon} alt="Twitter" />
            </Link>
          </div>
          <div>
            <Link to="/covlant/linkedin">
              <img src={linkedInIcon} alt="LinkedIn" />
            </Link>
          </div>
          <div>
            <Link to="/covlant/instagram">
              <img src={instagramIcon} alt="Instagram" />
            </Link>
          </div>
        </div>
        <div className="text-[#141B34] font-satoshi text-[16px]">
          <p>© Covlant 2024 • All rights reserved.</p>
        </div>
        <div className="space-x-[16px] font-satoshi text-[#141B34] text-[16px]">
          <Link to="">Terms of Use</Link>
          <Link to="">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
