import background from "../../Assets/backgrounds/landingPageBackgroundLightTheme.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

function LandingPage() {
  const landingPageHeadingStyle =
    "text-black font-satoshi text-center sm:text-left font-normal text-4xl sm:text-6xl";
  const landingPageBodyActionButtonStyle =
    "px-3 py-6  h-[46px] rounded-[12px] border-solid border-[#2742AB] border font-bold text-base font-satoshi";

  return (
    <div className="h-screen lg:min-h-[770px] flex flex-col">
      <Header />
      <div className="container mx-auto max-w-[1170px] flex flex-grow lg:relative">
        <div className="flex flex-col w-full lg:flex-row items-center lg:relative">
          <div className="flex flex-col gap-y-5 z-10">
            <div className="flex flex-col items-center lg:items-start lg:gap-y-3 z-10">
              <p className={landingPageHeadingStyle}>Developer's</p>
              <p className={landingPageHeadingStyle}>Productivity Multiplier</p>
              <p className="font-satoshi font-normal text-base text-center sm:text-left max-w-[531px] text-[#447CBF]">
                The Generative AI Assistant to Turbocharge Software Development
              </p>
            </div>
            <div className="flex flex-row justify-center lg:justify-start lg:items-start gap-x-3 mx-auto sm:mx-0">
              <button
                className={`${landingPageBodyActionButtonStyle} bg-[#2742AB] text-white`}
                onClick={() => (window.location.href = "/beta-signup")}
              >
                Beta Signup
              </button>
            </div>
          </div>
          <div className="max-h-[534px] lg:absolute lg:right-0">
            <img
              className="max-h-[534px]"
              src={background}
              alt="landingPageImage"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
